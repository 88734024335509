'use client'
import { CircularProgress, Container } from '@mui/material'
import { Center } from 'ui/mui'

export default function Loading() {
	return (
		<Container maxWidth={false}>
			<Center height="100dvh">
				<CircularProgress />
			</Center>
		</Container>
	)
}
