import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { FlexCol } from './Box';
const h2Props = {
    variant: 'h2',
    fontSize: '18px',
    py: 1,
    px: 2,
    backgroundColor: 'primary.600',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px',
};
export const Headline = ({ children, ...typographyProps }) => {
    return (_jsx(Typography, { ...h2Props, ...typographyProps, children: children }));
};
export const LabeledContent = ({ children, dataCy, label, }) => {
    return (_jsxs(FlexCol, { children: [_jsxs(Typography, { variant: "subtitle2", children: ["\u30FB", label] }), _jsx(Typography, { variant: "body1", fontSize: 14, pl: 3, "data-cy": dataCy, whiteSpace: "pre-wrap", children: children })] }));
};
