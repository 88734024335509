import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { Button, styled } from '@mui/material';
import { useState } from 'react';
const buttonStyle = {
    fontWeight: 'bold',
    borderRadius: 4,
};
export const AppLoadingButton = styled(({ children, dataCy, onClick, ...buttonProps }) => {
    const [loading, setLoading] = useState(false);
    const handleClick = (e) => {
        setLoading(true);
        onClick?.(e).finally(() => {
            setLoading(false);
        });
    };
    return (_jsx(MuiLoadingButton, { variant: "contained", loading: loading, onClick: handleClick, disableElevation: true, ...buttonProps, "data-cy": dataCy, children: children }));
})(buttonStyle);
const OutlinedButtonBase = (props) => _jsx(Button, { variant: "outlined", ...props });
export const OutlinedButton = styled(OutlinedButtonBase)(buttonStyle);
const ContainedButtonBase = (props) => _jsx(Button, { variant: "contained", disableElevation: true, ...props });
export const ContainedButton = styled(ContainedButtonBase)(buttonStyle);
const TextButtonBase = (props) => _jsx(Button, { variant: "text", ...props });
export const TextButton = styled(TextButtonBase)(buttonStyle);
