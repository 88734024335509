import { jsx as _jsx } from "react/jsx-runtime";
import { Fade, Paper, Popper, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material';
export const DescriptionCell = ({ children, maxWidth, minWidth }) => {
    return (_jsx(TableCell, { sx: {
            maxWidth,
            minWidth,
            cursor: 'default',
        }, children: _jsx(Typography, { sx: {
                wordBreak: 'break-all',
            }, children: children }) }));
};
const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(145deg, #ffffff, #f8f9fa)',
    border: '1px solid #e0e0e0',
    padding: theme.spacing(3),
    maxWidth: 400,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
}));
export const PopperWithTransition = ({ children, disablePortal = true, paperProps, ...popperProps }) => {
    return (_jsx(Popper, { ...popperProps, transition: true, style: { zIndex: 100 }, placement: "auto", disablePortal: disablePortal, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 300, children: _jsx(StyledPaper, { ...paperProps, sx: paperProps?.sx
                    ? {
                        p: 2,
                        ...paperProps.sx,
                    }
                    : { p: 2 }, children: children }) })) }));
};
